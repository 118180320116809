import React, { useState } from "react"

import styles from "./hero.module.css"
import classNames from "classnames"
import { Link } from "gatsby"
import ButtonRounded from "../../ButtonRounded"

const Hero = () => {
  const [videoScaled, setVideoScaled] = useState(false)

  return (
    <div
      className={classNames(styles.container, {
        [styles.videoScaled]: videoScaled,
      })}
    >
      <div className={styles.coreContent}>
        <div className={styles.title}>
          From idea to solution.
          <br />{" "}
          <span className={styles.titleBold}>
            We craft digital experiences.
          </span>
        </div>
        <div className={styles.subtitle}>
          Based in London, we’re a technology studio building performant{" "}
          <Link to="/services">Websites</Link>, <Link to="/services">Apps</Link>{" "}
          & <Link to="/services">E-stores</Link> for forward thinking start-ups,
          scale-ups and enterprises
        </div>
        <div className={styles.actions}>
          <div className={styles.buttonContainer}>
            <ButtonRounded to="/contact" text="Start here" />
            <Link to="/contact" className={styles.bookLink}>
              // Book a free call
            </Link>
          </div>
        </div>
        <div
          className={classNames(styles.videoContainer)}
          onClick={() => setVideoScaled(true)}
          onMouseLeave={() => {
            setVideoScaled(false)
          }}
        >
          <video
            src="/images/undefined-demo.mp4"
            className={styles.video}
            autoPlay
            loop
            preload="metadata"
            muted={!videoScaled}
            playsinline
          />
        </div>
      </div>
    </div>
  )
}

export default Hero
