import React from "react"

import wild from "../../images/work/wild.png"
import takeplace from "../../images/work/takeplacelogo.png"
import macmillan from "../../images/work/macmillan.png"
import life from "../../images/work/life.png"
import ferly from "../../images/work/ferly.png"
import ogilvy from "../../images/work/ogilvy.png"
import LinkArrow from "../../images/LinkArrow"
import growyze from "../../images/services/growyze.png"
import lexology from "../../images/services/lexology.png"
import punchyWeb from "../../images/services/punchyWeb.png"
import punchyApp from "../../images/services/punchyApp.png"
import bolt from "../../images/bolt.png"
import quote from "../../images/quote.png"

import styles from "./ourApproach.module.css"
import CornerContainer from "../CornerContainer/CornerContainer"
import { Link } from "gatsby"

const OurApproach = (
  {
    // content: {
    //   title,
    //   subtitle,
    //   header,
    //   gridItems: {
    //     item1: { title, subtitle },
    //   },
    // },
  }
) => {
  return (
    <CornerContainer>
      <div className={styles.container}>
        <div className={styles.button}>
          <div className={styles.boltCircle}>
            <img src={bolt} />
          </div>
          <div>Our Approach</div>
        </div>
        <div className={styles.subtext}>
          Your domain know-how, our product expertise
        </div>
        <div className={styles.description}>
          We love collaborating to build solutions that hit the mark. Working on
          your ideas to draw a smile on the face of your users.
        </div>
        <div className={styles.itemContainer}>
          {/* Validate Ideas */}
          <Link to="/services" className={styles.gridItem}>
            <div className={styles.itemTitle}>Don’t know where to start?</div>
            <div className={styles.itemSubtitle}>Rapidly Validate Ideas</div>
            <div className={styles.itemDescription}>
              Our Progressive Prototyping approach allows us to start from the
              beginning and create a functional prototype that you can test in
              the market. This prototype can be refined and improved through
              iteration, ultimately leading to a successful product launch.
            </div>
            <div className={styles.itemImage}>
              <img className={styles.image1} src={growyze} alt="growyze" />
            </div>
          </Link>

          {/* Full Dev */}
          <Link to="/services" className={styles.gridItem}>
            <div className={styles.itemTitle}>
              Already found product market fit?
            </div>
            <div className={styles.itemSubtitle}>
              Full Cycle software development
            </div>
            <div className={styles.itemDescription}>
              We prioritise delivering fast and seamless app and web experiences
              that meet the high expectations of our users through top quality
              coding techniques (and some coffee).
            </div>
            <div className={styles.itemImage}>
              <img className={styles.image2} src={lexology} alt="lexology" />
            </div>
          </Link>

          {/* D2C */}
          <Link to="/services" className={styles.gridItem}>
            <div className={styles.itemTitle}>Upgrade your D2C presence</div>
            <div className={styles.itemSubtitle}>Sites that convert</div>
            <div className={styles.itemDescription}>
              With years of experience as a Shopify partner, we’re no stranger
              to the world of E-commerce. By hyper-charging this experience with
              a Headless approach, we aim to deliver performant experiences that
              your customers will love.
            </div>
            <div className={styles.itemImage}>
              <img className={styles.image3a} src={punchyApp} alt="punchyapp" />
              <img className={styles.image3b} src={punchyWeb} alt="punchyweb" />
            </div>
          </Link>

          {/* Work With us */}
          <div className={styles.finalGridItem} key="gridItemExtra">
            <div className={styles.itemExtraTitle}>And that’s not all</div>
            <div className={styles.itemExtraLink}>
              <Link to="/contact">
                Work with us <LinkArrow />
              </Link>
            </div>
            <div className={styles.itemExtraInfo}>
              <div className={styles.infoItem}>Progressive Web App</div>
              <div className={styles.infoItem}>Headless E-commerce</div>
              <div className={styles.infoItem}>JAM-Stack websites</div>
              <div className={styles.infoItem}>Progressive Prototyping</div>
              <div className={styles.infoItem}>Headless CMS</div>
              <div className={styles.infoItem}>Interface Design</div>
              <div className={styles.infoItem}>iOS Apps</div>
              <div className={styles.infoItem}>Android Apps</div>
            </div>
            <Link to="/services" className={styles.viewAll}>
              View All
            </Link>
          </div>
        </div>

        <div className={styles.quoteContainer}>
          <img className={styles.quoteMark} src={quote} alt="quote" />

          <figure className={styles.figure}>
            <blockquote className={styles.quote}>
              They were very responsive, friendly, and committed to getting our
              site live and on budget.
            </blockquote>
            <figcaption className={styles.smallText}>
              Miranda Essex, Head of growth @ Thrift Plus
            </figcaption>
          </figure>
        </div>

        <div className={styles.clientLogosContainer}>
          <div className={styles.smallText}>Also trusted by</div>
          <img className={styles.logo} src={ogilvy} alt="ogilvy" />
          <img className={styles.logo} src={takeplace} alt="takeplace" />
          <img className={styles.logo} src={macmillan} alt="macmillan" />
          <img className={styles.logo} src={ferly} alt="ferly" />
          <img className={styles.logo} src={life} alt="life" />
        </div>
      </div>
    </CornerContainer>
  )
}

export default OurApproach
